.btn-upload {
  position: relative;
  overflow: hidden;
}
.btn-upload input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translate(-300px, 0) scale(4);
  -webkit-transform: translate(-300px, 0) scale(4);
  -ms-transform: translate(-300px, 0) scale(4);
  -o-transform: translate(-300px, 0) scale(4);
  font-size: 23px;
  direction: ltr;
  cursor: pointer;
}
* html .btn-upload {
  line-height: 24px;
  margin: 1px -3px 0 0;
}
* + html .btn-upload {
  padding: 2px 15px;
  margin: 1px 0 0 0;
}
